import React from 'react';
import Logo from '../../static/img/csrc_logo.png';
import { Typography } from '@mui/material';

const HeaderIcon = (props) => {
    return (
        <div
            className="flex flex-row space-x-2 items-center cursor-pointer"
            onClick={props.onClickLogo}
        >
            <img src={Logo} className="header-icon"/>
            <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    PNU CASB Dashboard
                </Typography>
        </div>
    );
};
export default HeaderIcon;
