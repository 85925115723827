import React from 'react';
import { ThemeProvider } from '@mui/material';
import { AuthProvider } from './core/auth';
import { UserProvider } from './core/user';
import theme from './theme';
import './index.css';

function App({ children }) {
    return (
        <ThemeProvider theme={theme}>
            <AuthProvider>
                <UserProvider>
                    {children}
                </UserProvider>
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;