import { createTheme } from '@mui/material/styles';

const colors = {
    blue: {
        100: '#E3F2FD',
        200: '#BBDEFB',
        300: '#90CAF9',
        400: '#64B5F6',
        500: '#42A5F5',
        600: '#2196F3',
        700: '#1E88E5',
        800: '#1976D2',
        900: '#1565C0',
    },
    grey: {
        100: '#F5F7FA',
        200: '#E4E7EB',
        300: '#CBD2D9',
        400: '#9AA5B1',
        500: '#7B8794',
        600: '#616E7C',
        700: '#52606D',
        800: '#3E4C59',
        900: '#323F4B',
    },
    green: {
        100: '#E8F5E9',
        200: '#C8E6C9',
        300: '#A5D6A7',
        400: '#81C784',
        500: '#66BB6A',
        600: '#4CAF50',
        700: '#43A047',
        800: '#388E3C',
        900: '#2E7D32',
    },
    indigo: {
        100: '#E8EAF6',
        200: '#C5CAE9',
        300: '#9FA8DA',
        400: '#7986CB',
        500: '#5C6BC0',
        600: '#3F51B5',
        700: '#3949AB',
        800: '#303F9F',
        900: '#283593',
    },
    orange: {
        100: '#FFF3E0',
        200: '#FFE0B2',
        300: '#FFCC80',
        400: '#FFB74D',
        500: '#FFA726',
        600: '#FF9800',
        700: '#FB8C00',
        800: '#F57C00',
        900: '#EF6C00',
    },
    red: {
        100: '#FFEBEE',
        200: '#FFCDD2',
        300: '#EF9A9A',
        400: '#E57373',
        500: '#EF5350',
        600: '#F44336',
        700: '#E53935',
        800: '#D32F2F',
        900: '#C62828',
    },
};

const theme = createTheme({
    palette: {
        primary: {
            main: colors.blue[600],
            light: colors.blue[400],
            dark: colors.blue[800],
        },
        secondary: {
            main: colors.indigo[500],
            light: colors.indigo[300],
            dark: colors.indigo[700],
        },
        neutral: {
            main: colors.grey[500],
            light: colors.grey[300],
            dark: colors.grey[700],
        },
        error: {
            main: colors.red[600],
        },
        warning: {
            main: colors.orange[600],
        },
        info: {
            main: colors.blue[500],
        },
        success: {
            main: colors.green[600],
        },
        background: {
            default: '#ffffff',
            paper: '#ffffff',
        },
        text: {
            primary: colors.grey[900],
            secondary: colors.grey[700],
        },
    },
    typography: {
        fontFamily: ['Inter', 'Roboto', 'Arial', 'sans-serif'].join(','),
        h1: {
            fontWeight: 700,
            fontSize: '2.75rem', // 44px
        },
        h2: {
            fontWeight: 600,
            fontSize: '2.25rem', // 36px
        },
        h3: {
            fontWeight: 600,
            fontSize: '2rem', // 32px
        },
        h4: {
            fontWeight: 600,
            fontSize: '1.75rem', // 28px
        },
        h5: {
            fontWeight: 600,
            fontSize: '1.5rem', // 24px
        },
        h6: {
            fontWeight: 600,
            fontSize: '1.25rem', // 20px
        },
        body1: {
            fontSize: '1.125rem', // 18px
        },
        body2: {
            fontSize: '1rem', // 16px
        },
        button: {
            fontSize: '1.125rem', // 18px
        },
        caption: {
            fontSize: '0.875rem', // 14px
        },
        overline: {
            fontSize: '0.75rem', // 12px
            textTransform: 'uppercase',
            letterSpacing: '0.08em',
        },
    },
    shape: {
        borderRadius: 8,
    },
    shadows: [
        'none',
        '0px 2px 4px rgba(0, 0, 0, 0.05)',
        '0px 4px 6px rgba(0, 0, 0, 0.1)',
        '0px 6px 8px rgba(0, 0, 0, 0.15)',
        '0px 8px 12px rgba(0, 0, 0, 0.2)',
        // ... (you can define more shadow levels if needed)
    ],
});

export default theme;
